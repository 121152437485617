// Variable globale pour stocker la liste des séries
let series_list = [];

// Fonction d'initialisation globale
function initializeSearchFunctionality(seriesList) {
    series_list = seriesList;
    const searchInput = document.getElementById("search_serie");
    if (searchInput) {
        autocomplete(searchInput, series_list);
        autocomplt();
    }
}

// Fonction principale pour l'envoi des données
async function sendData(form, target, options = {}) {
    const defaults = {
        method: 'POST',
        needsConfirmation: false,
        confirmationMessage: 'Êtes-vous sûr ?',
        successMessage: null,
        jsonMode: false
    };

    const settings = { ...defaults, ...options };

    if (settings.needsConfirmation && !confirm(settings.confirmationMessage)) {
        return;
    }

    try {
        console.log('Sending data to:', target);
        console.log('Settings:', settings);

        let body;
        const headers = {};
        
        if (settings.jsonMode) {
            const formData = new FormData(form);
            const data = {};
            formData.forEach((value, key) => {
                data[key] = !isNaN(value) && value !== '' ? Number(value) : value;
            });
            body = JSON.stringify(data);
            headers['Content-Type'] = 'application/json';
        } else {
            body = new FormData(form);
        }

        const submitButton = form.querySelector('button[type="submit"]');
        if (submitButton) submitButton.disabled = true;

        const response = await fetch(target, {
            method: settings.method,
            headers: headers,
            body: body
        });

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new Error(`Response is not JSON: ${contentType}`);
        }

        const result = await response.json();

        if (result.success) {
            const modalElement = form.closest('.modal');
            if (modalElement) {
                const modal = bootstrap.Modal.getInstance(modalElement);
                if (modal) modal.hide();
            }

            if (settings.successMessage || result.message) {
                alert(settings.successMessage || result.message);
            }

            window.location.reload();
        } else {
            throw new Error(result.message || 'Une erreur est survenue');
        }

    } catch (error) {
        console.error('Error:', error);
        if (error.message !== 'User cancelled') {
            alert(error.message || 'Une erreur est survenue lors de la requête');
        }
    } finally {
        const submitButton = form.querySelector('button[type="submit"]');
        if (submitButton) submitButton.disabled = false;
    }
}

// Configuration et initialisation des formulaires
document.addEventListener('DOMContentLoaded', function() {
    const formConfigs = {
        'reservation-form': {
            url: '/reservation',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Confirmez-vous la demande de prêt ?'
        },
        'cancel-reservation-form': {
            url: '/annulation_resa',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Êtes-vous sûr de vouloir annuler cette réservation ?'
        },
        'delete-volume-form': {
            url: '/supprimer_volume',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Êtes-vous sûr de vouloir supprimer ce volume ?'
        },
        'declare-return-form': {
            url: '/declare_rendu_preteur',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Confirmez-vous avoir reçu ce livre en retour ?'
        },
        'confirm-return-form': {
            url: '/valid_rendu',
            needsConfirmation: true,
            confirmationMessage: 'Confirmez-vous le retour de ce livre ?'
        },
        'declare-borrower-return-form': {
            url: '/rendu',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Confirmez-vous la déclaration de retour ?'
        },
        'validate-loan-form': {
            url: '/valid_pret',
            jsonMode: true,
            needsConfirmation: true,
            confirmationMessage: 'Confirmez-vous la validation de ce prêt ?'
        }
    };

    document.querySelectorAll('.delete-serie-btn').forEach(button => {
        button.addEventListener('click', async function() {
            if (!confirm('Êtes-vous sûr de vouloir supprimer cette série et tous ses volumes ?')) {
                return;
            }

            try {
                const serieId = this.getAttribute('onclick').match(/\d+/)[0];
                const response = await fetch('/supprimer_serie', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id: parseInt(serieId) })
                });

                const result = await response.json();
                if (result.success) {
                    window.location.reload();
                } else {
                    throw new Error(result.message);
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message || 'Une erreur est survenue lors de la suppression');
            }
        });
    });

    document.querySelectorAll('form:not(#loginForm)').forEach(form => {
        form.replaceWith(form.cloneNode(true));
    });

    document.querySelectorAll('form:not(#loginForm)').forEach(form => {
        form.addEventListener('submit', async function(e) {
            e.preventDefault();
            e.stopPropagation();

            let config = null;
            for (const [className, conf] of Object.entries(formConfigs)) {
                if (form.classList.contains(className)) {
                    config = conf;
                    break;
                }
            }

            if (form.id === 'add_volume_form') {
                const mode = form.querySelector('input[name="addMode"]:checked').value;
                config = {
                    url: mode === 'batch' ? '/addvolumes' : '/addvolume',
                    jsonMode: true
                };
            }

            if (!config && form.classList.contains('needs-validation')) {
                config = {
                    url: form.getAttribute('action'),
                    jsonMode: false
                };
            }

            if (config) {
                await sendData(form, config.url, config);
            }
        });
    });
});

// Fonction pour la gestion des modes d'ajout de volumes
function toggleAddMode(mode) {
    document.getElementById('singleAdd').style.display = mode === 'single' ? 'block' : 'none';
    document.getElementById('batchAdd').style.display = mode === 'batch' ? 'block' : 'none';
    
    document.getElementById('volume').required = (mode === 'single');
    document.getElementById('startVolume').required = (mode === 'batch');
    document.getElementById('endVolume').required = (mode === 'batch');
}

