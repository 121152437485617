// assets/js/accessibility.js

class AccessibilityManager {
    constructor() {
        this.init();
    }

    init() {
        this.setupFormValidation();
        this.setupSkipLink();
        this.setupModalAccessibility();
        this.setupStatusMessages();
        this.setupContrastToggle();
        this.setupKeyboardNavigation();
    }

    // Gestion de la validation des formulaires
    setupFormValidation() {
        const forms = document.querySelectorAll('form');
        forms.forEach(form => {
            // Initialisation des champs
            const inputs = form.querySelectorAll('input, select, textarea');
            inputs.forEach(input => this.setupInputValidation(input));

            // Gestion de la soumission
            form.addEventListener('submit', (e) => {
                inputs.forEach(input => this.validateInput(input));
            });
        });
    }

    // Configuration de la validation pour un input
    setupInputValidation(input) {
        // Création du message d'erreur s'il n'existe pas
        if (!document.getElementById(`${input.id}-error`)) {
            const errorDiv = document.createElement('div');
            errorDiv.id = `${input.id}-error`;
            errorDiv.className = 'invalid-feedback';
            errorDiv.setAttribute('role', 'alert');
            input.parentNode.appendChild(errorDiv);
        }

        // Ajout des attributs ARIA
        input.setAttribute('aria-invalid', 'false');
        input.setAttribute('aria-describedby', `${input.id}-error`);

        // Validation en temps réel
        input.addEventListener('input', () => this.validateInput(input));
        input.addEventListener('blur', () => this.validateInput(input));
    }

    // Validation d'un input
    validateInput(input) {
        const errorDiv = document.getElementById(`${input.id}-error`);
        if (!errorDiv) return;

        let isValid = true;
        let errorMessage = '';

        // Validation required
        if (input.required && !input.value.trim()) {
            isValid = false;
            errorMessage = 'Ce champ est requis';
        }

        // Validation email
        if (input.type === 'email' && input.value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(input.value)) {
                isValid = false;
                errorMessage = 'Veuillez entrer une adresse email valide';
            }
        }

        // Validation pattern
        if (input.pattern && input.value) {
            const pattern = new RegExp(input.pattern);
            if (!pattern.test(input.value)) {
                isValid = false;
                errorMessage = input.title || 'Format invalide';
            }
        }

        // Validation minlength/maxlength pour les champs texte
        if (input.type === 'text') {
            if (input.minLength && input.value.length < input.minLength) {
                isValid = false;
                errorMessage = `Minimum ${input.minLength} caractères requis`;
            }
            if (input.maxLength && input.value.length > input.maxLength) {
                isValid = false;
                errorMessage = `Maximum ${input.maxLength} caractères autorisés`;
            }
        }

        // Mise à jour de l'état et du message
        this.updateInputState(input, errorDiv, isValid, errorMessage);
    }

    // Mise à jour de l'état d'un input
    updateInputState(input, errorDiv, isValid, errorMessage) {
        input.setAttribute('aria-invalid', (!isValid).toString());
        
        if (!isValid) {
            input.classList.add('is-invalid');
            input.classList.remove('is-valid');
            errorDiv.textContent = errorMessage;
            errorDiv.style.display = 'block';
        } else {
            input.classList.remove('is-invalid');
            input.classList.add('is-valid');
            errorDiv.style.display = 'none';
        }
    }

    // Configuration du lien "Aller au contenu"
    setupSkipLink() {
        const skipLink = document.createElement('a');
        skipLink.href = '#main';
        skipLink.className = 'skip-link';
        skipLink.textContent = 'Aller au contenu principal';
        document.body.insertBefore(skipLink, document.body.firstChild);
    }

    // Configuration de l'accessibilité des modales
    setupModalAccessibility() {
        const modals = document.querySelectorAll('.modal');
        modals.forEach(modal => {
            this.setupModal(modal);
        });
    }

    // Configuration d'une modale
    setupModal(modal) {
        modal.addEventListener('show.bs.modal', () => {
            // Focus sur le premier champ du formulaire
            const firstInput = modal.querySelector('input, select, textarea');
            if (firstInput) {
                setTimeout(() => firstInput.focus(), 100);
            }
        });

        // Gestion du focus dans la modale
        modal.addEventListener('keydown', (e) => {
            if (e.key === 'Tab') {
                this.handleModalTabKey(e, modal);
            }
        });
    }

    // Gestion de la touche Tab dans les modales
    handleModalTabKey(e, modal) {
        const focusableElements = modal.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstFocusable = focusableElements[0];
        const lastFocusable = focusableElements[focusableElements.length - 1];

        if (e.shiftKey && document.activeElement === firstFocusable) {
            lastFocusable.focus();
            e.preventDefault();
        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
            firstFocusable.focus();
            e.preventDefault();
        }
    }

    // Configuration des messages de statut
    setupStatusMessages() {
        const statusMessages = document.querySelectorAll('.alert, .toast');
        statusMessages.forEach(message => {
            message.setAttribute('role', 'status');
            message.setAttribute('aria-live', 'polite');
        });
    }

    // Configuration du bouton de contraste
    setupContrastToggle() {
        const toggleBtn = document.createElement('button');
        toggleBtn.className = 'contrast-toggle';
        toggleBtn.setAttribute('aria-label', 'Basculer le contraste élevé');
        toggleBtn.innerHTML = '<i class="bi bi-circle-half"></i>';

        const navbar = document.querySelector('.navbar .navbar-nav');
        if (navbar) {
            navbar.appendChild(toggleBtn);

            toggleBtn.addEventListener('click', () => {
                document.body.classList.toggle('high-contrast');
                const isHighContrast = document.body.classList.contains('high-contrast');
                localStorage.setItem('highContrast', isHighContrast);
                toggleBtn.setAttribute('aria-pressed', isHighContrast.toString());
            });

            // Restaurer la préférence
            if (localStorage.getItem('highContrast') === 'true') {
                document.body.classList.add('high-contrast');
                toggleBtn.setAttribute('aria-pressed', 'true');
            }
        }
    }

    // Configuration de la navigation au clavier
    setupKeyboardNavigation() {
        document.addEventListener('keydown', (e) => {
            // Alt + chiffre pour la navigation rapide
            if (e.altKey && !isNaN(e.key)) {
                const index = parseInt(e.key) - 1;
                const navLinks = document.querySelectorAll('.navbar .nav-link');
                if (navLinks[index]) {
                    e.preventDefault();
                    navLinks[index].click();
                }
            }
        });
    }
}

// Initialisation au chargement de la page
document.addEventListener('DOMContentLoaded', () => {
    window.accessibilityManager = new AccessibilityManager();
});