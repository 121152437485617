// Fonction principale de configuration de la recherche
function setupSearch(searchInput, seriesList) {
  if (!searchInput || !seriesList) return;

  // Création de la div pour les suggestions
  const suggestionsDiv = document.createElement('div');
  suggestionsDiv.className = 'autocomplete-items';
  suggestionsDiv.style.display = 'none';
  searchInput.parentNode.appendChild(suggestionsDiv);

  // Gestionnaire de recherche
  searchInput.addEventListener('input', debounce(function(e) {
      const searchTerm = e.target.value.trim().toLowerCase();
      
      // Filtrer les séries
      filterSeries(searchTerm);
      
      // Mettre à jour les suggestions
      updateSuggestions(searchTerm, seriesList, suggestionsDiv, searchInput);
  }, 300));

  // Fermer les suggestions au clic ailleurs
  document.addEventListener('click', function(e) {
      if (e.target !== searchInput) {
          suggestionsDiv.style.display = 'none';
      }
  });

  // Navigation au clavier dans les suggestions
  searchInput.addEventListener('keydown', function(e) {
      handleKeyboardNavigation(e, suggestionsDiv, searchInput);
  });
}

// Fonction de filtrage des séries
function filterSeries(searchTerm) {
  const cards = document.querySelectorAll('.card-container');
  
  if (!searchTerm) {
      showAllSeries();
      return;
  }

  cards.forEach(card => {
      const title = card.getAttribute('data-titre').toLowerCase();
      if (title.startsWith(searchTerm)) {
          card.style.display = '';
      } else {
          card.style.display = 'none';
      }
  });
}

// Fonction pour afficher toutes les séries
function showAllSeries() {
  const cards = document.querySelectorAll('.card-container');
  cards.forEach(card => {
      card.style.display = '';
  });
}

// Fonction de mise à jour des suggestions
function updateSuggestions(searchTerm, seriesList, suggestionsDiv, searchInput) {
  suggestionsDiv.innerHTML = '';
  
  if (!searchTerm) {
      suggestionsDiv.style.display = 'none';
      return;
  }

  const matches = seriesList.filter(title => 
      title.toLowerCase().startsWith(searchTerm)
  );

  if (matches.length > 0) {
      suggestionsDiv.style.display = 'flex';
      matches.forEach(title => {
          const div = document.createElement('div');
          const matchEnd = searchTerm.length;
          div.innerHTML = `<strong>${title.substr(0, matchEnd)}</strong>${title.substr(matchEnd)}`;
          
          div.addEventListener('click', function() {
              searchInput.value = title;
              filterSeries(title.toLowerCase());
              suggestionsDiv.style.display = 'none';
          });
          
          suggestionsDiv.appendChild(div);
      });
  } else {
      suggestionsDiv.style.display = 'none';
  }
}

// Gestion de la navigation au clavier
function handleKeyboardNavigation(event, suggestionsDiv, searchInput) {
  const suggestions = suggestionsDiv.getElementsByTagName('div');
  if (!suggestions.length) return;

  let activeIndex = Array.from(suggestions).findIndex(div => 
      div.classList.contains('active')
  );

  switch(event.key) {
      case 'ArrowDown':
          event.preventDefault();
          activeIndex = (activeIndex + 1) % suggestions.length;
          updateActiveSelection(suggestions, activeIndex);
          break;
          
      case 'ArrowUp':
          event.preventDefault();
          activeIndex = activeIndex <= 0 ? suggestions.length - 1 : activeIndex - 1;
          updateActiveSelection(suggestions, activeIndex);
          break;
          
      case 'Enter':
          event.preventDefault();
          const activeElement = suggestionsDiv.querySelector('.active');
          if (activeElement) {
              activeElement.click();
          }
          break;
          
      case 'Escape':
          suggestionsDiv.style.display = 'none';
          break;
  }
}

// Mise à jour de la sélection active
function updateActiveSelection(suggestions, activeIndex) {
  Array.from(suggestions).forEach((div, index) => {
      div.classList.toggle('active', index === activeIndex);
  });
}

// Utilitaire pour debounce
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
      const later = () => {
          clearTimeout(timeout);
          func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
}